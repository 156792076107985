<template>
  <div>
    <div v-for="(product, product_index) in product_detail" :key="_uid+'services-all'+product_index">
      <section class="section services-all"
               v-if="statics_detail[product.baseProdotto.pid].schedaProdotto.caratteristiche_prodotto.known.lista_servizi"
      >
        <h2 class="vb-heading mb-4">Tutti i servizi</h2>
        <b-row>
          <b-col>
            <ul class="services-all__list">
              <li class="services-all__list-item"
                  v-for="(service, service_index) in statics_detail[product.baseProdotto.pid].schedaProdotto.caratteristiche_prodotto.known.lista_servizi"
                  v-bind:key="_uid+service_index">
                <vb-icon
                    :name="'check'"
                    :size=12
                    class="services-all__list-icon"
                /><span class="d-inline-block align-middle">{{ service.nome }}</span></li>
            </ul>
          </b-col>
        </b-row>
      </section>
    </div>
  </div>
</template>

<script>


export default {
  name: 'services-all',

  props: {
    statics_detail: Object,
    product_detail: Array,
  },

  components: {
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style lang="scss">

  .services-all {
    background-color: $white;
    border-radius: $border-radius-lg;
    padding: $grid-gutter-width;

    &__list {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;

      &-icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }

      &-item {
        display: inline-block;
        margin-right: 15px;
        line-height: 3rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

</style>