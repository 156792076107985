<template>
  <div>
    <div v-for="(product, product_index) in product_detail" :key="_uid+'panoramic-hotel'+product_index">
      <section class="section panoramic"
               v-if="statics_detail[product.baseProdotto.pid].schedaProdotto.caratteristiche_prodotto.known.panoramica_hotel"
      >
        <b-row>
          <b-col lg="3" class="panoramic__item mt-3"
                 v-for="(pan, pan_index) in statics_detail[product.baseProdotto.pid].schedaProdotto.caratteristiche_prodotto.known.panoramica_hotel"
                 v-bind:key="_uid+pan_index"
          >
            <vb-card v-if="pan.note_caratteristica !== ''" :card="{
              title: pan.nome,
              text: pan.note_caratteristica,
            }" />
          </b-col>
        </b-row>

      </section>
    </div>
  </div>
</template>

<script>
import Card from '@library/blocks/card'

export default {
  name: 'panoramic-hotel',

  props: {
    statics_detail: Object,
    product_detail: Array,
  },

  components: {
    'vb-card': Card,
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>

  .panoramic {

    &__item:empty {
      display: none;
    }
  }

</style>