<template>
  <div class="carousel">
    <div v-for="(product, product_index) in product_detail" :key="_uid+'carousel'+product_index" :set_filtered_images="filtered_images=get_filtered_images(product)">
      <div v-if="filtered_images.length">

        <!--main carousel-->
        <swiper-container init="false" ref="swiper-container" class="carousel__main">
          <swiper-slide v-for="(image, image_index) in filtered_images" v-bind:key="_uid+image_index" class="carousel__item">
            <a :href="image.src"
              :data-pswp-width="image.width" 
              :data-pswp-height="image.height">
              <img class="carousel__image" :src="image.src" />
            </a>
          </swiper-slide>
        </swiper-container>

        <!--thumbs carousel-->
        <swiper-container init="false" ref="swiper-thumbs" class="carousel__thumbs">
          <swiper-slide
            v-for="(image, image_index) in filtered_images"
            v-bind:key="_uid+image_index"
            class="carousel__item"
            @click="swiper_instance.swiper.slideTo(image_index)"
            :class="{ active: image_index == current_slide_index }"
          >
            <img class="carousel__image" :src="image.src" />
          </swiper-slide>
        </swiper-container>

        <!--navigation-->
        <vb-icon :name="'angle_left'" :size="26" class="carousel__arrow carousel__arrow--prev" @click="swiper_instance.swiper.slidePrev()" />
        <vb-icon :name="'angle_right'" :size="26" class="carousel__arrow carousel__arrow--next" @click="swiper_instance.swiper.slideNext()" />
      </div>
    </div>
  </div>
</template>

<script>
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js'
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm.js'
import 'photoswipe/dist/photoswipe.css'

export default {
  name: 'carousel',

  props: {
    statics_detail: Object,
    product_detail: Array,
  },

  components: {
  },

  data() {
    const vm = this
    return {
      lightbox: null,
      image_min_width: 800,
      image_min_height: 600,
      current_slide_index: 0,
      swiper_instance: {},
      swiper_thumbs: {},
      swiper_thumbs_params: {
        slidesPerView: 4,
        on: {
          slideChange(event) {
            vm.swiper_instance.swiper.slideTo(event.activeIndex)
          }
        },
        breakpoints: {
          // BP: md
          1024: {
            slidesPerView: 6,
          },
          // BP: xl
          1200: {
            slidesPerView: 8,
          },
        },        
      },
      swiper_params: {
        slidesPerView: 1,
        on: {
          slideChange(event) {
            vm.swiper_thumbs.swiper.slideTo(event.activeIndex)
            vm.current_slide_index = event.activeIndex
          },
        },
      }
    }
  },

  created() {
  },

  computed: {
  },

  mounted() {
    // carousel thumb
    const swiper_thumbs = this.$refs['swiper-thumbs'][0]
    Object.assign(swiper_thumbs, this.swiper_thumbs_params)
    swiper_thumbs.initialize()
    this.swiper_thumbs = swiper_thumbs

    // carousel full
    const swiper_instance = this.$refs['swiper-container'][0]
    Object.assign(swiper_instance, this.swiper_params)
    swiper_instance.initialize()
    this.swiper_instance = swiper_instance

    // zoom on click
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        showHideAnimationType: 'fade',
        gallery: '.carousel',
        children: 'a',
        pswpModule: PhotoSwipe,
      })
      this.lightbox.init()
      this.lightbox.on('change', () => {
        this.swiper_instance.swiper.slideTo(this.lightbox.pswp.currIndex)
      })
    }
  },

  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy()
      this.lightbox = null
    }
  },  

  methods: {
    get_filtered_images(product) {
      return this.statics_detail[product.baseProdotto.pid].schedaProdotto.immagini.filter(image => {
        return (image.width >= this.image_min_width && image.height >= this.image_min_height)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  .carousel {
    $this: &;

    position: relative;
    border-radius: $border-radius;
    overflow: hidden;

    &__main {
      border-radius: $border-radius;
      overflow: hidden;
    }

    &__thumbs {
      margin-top: 6px;
      display: none;

      @include media-breakpoint-up(sm) {
        display: block
      }
    }

    &__item {
      position: relative;

      @at-root #{$this}__main & {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 270px;

        @include media-breakpoint-up(lg) {
          height: 500px;
        }
      }

      @at-root #{$this}__thumbs & {
        cursor: pointer;
        padding-right: 3px;
        padding-left: 3px;
        height: 100px;

        @include media-breakpoint-up(md) {
          height: 150px;
        }

        &:after {
          position: absolute;
          top: 0;
          left: 3px;
          right: 3px;
          bottom: 0;
          transition: $transition-base;
          z-index: 1;
          border: 2px solid transparent;
          border-radius: $border-radius;
        }


      }

      &.active {
        &:after {
          content: "";
          border-color: $secondary;
        }
      }
    }

    &__image {
      border-radius: $border-radius;
      object-fit: cover;
      object-position: center center;
      min-height: 100%;
      min-width: 100%;

      @at-root #{$this}__thumbs & {

        &:hover {
          opacity: .6;
        }
      }

      @at-root #{$this}__item.active & {
        opacity: .4;
      }
    }

    &__arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 10;
      cursor: pointer;
      transition: $transition-base;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;

      @include media-breakpoint-up(sm) {
        bottom: 106px;
      }

      @include media-breakpoint-up(md) {
        bottom: 156px;
      }

      &--prev {
        left: 0;

        ::v-deep {
          svg {
            filter: drop-shadow(3px 0 0 $white)
          }
        }
      }
      &--next {
        right: 0;

        ::v-deep {
          svg {
            filter: drop-shadow(-3px 0 $white)
          }
        }
      }

      &:hover {
        @include media-breakpoint-up(lg) {
          background-color: rgba($white, 0.5);
        }
      }
    }
  }

</style>