<template>
  <div>
    <div v-for="(product, product_index) in product_detail" :key="_uid+'faq'+product_index">
      <section class="section faq"
               v-if="statics_detail[product.baseProdotto.pid].schedaProdotto.faq_struttura.length"
      >
        <h2 class="vb-heading mb-4">Domande frequenti</h2>
        <b-row>
          <!--      TODO: integrare il componente TOGGLE della library quando sarà implementato -->
          <b-col lg="6" class="mt-2" role="tablist"
                 v-for="(faq, faq_index) in statics_detail[product.baseProdotto.pid].schedaProdotto.faq_struttura"
                 v-bind:key="_uid+faq_index"
          >

            <vb-toggle
                :vb_options="{
                  id: 'accordion_'+ faq_index,
                  btn_text: faq.titolo,
                  accordion: 'accordion-faq'
                }"
            >
              <p>{{ faq.descrizione }}</p>
            </vb-toggle>

          </b-col>
        </b-row>
      </section>
    </div>
  </div>
</template>

<script>


export default {
  name: 'faq',

  props: {
    statics_detail: Object,
    product_detail: Array,
  },

  components: {
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style lang="scss">

  .faq {
    @extend %section_margin;
  }

</style>