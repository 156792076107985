<template>
  <div>
    <div v-for="(product, product_index) in product_detail" :key="_uid+'information'+product_index">
      <section class="section information">
        <p class="information__desc" v-if="statics_detail[product.baseProdotto.pid].schedaProdotto.perche_andare !== ''">
          <vb-icon
              :name="'quote'"
              :size=35
              :color="'f8af3c'"
              class="information__icon"
          />
          {{statics_detail[product.baseProdotto.pid].schedaProdotto.perche_andare}}
          <vb-icon
              :name="'quote'"
              :size=35
              :color="'f8af3c'"
              class="information__icon"
          />
        </p>
        <div v-for="(accommodation, accommodation_index) in statics_detail[product.baseProdotto.pid].schedaProdotto.sistemazioni"
             v-bind:key="_uid+accommodation_index">

          <vb-tabs v-if="accommodation.servizi.length">
            <b-tab v-for="(service, index) in accommodation.servizi" v-bind:key="_uid+index"
                   :title="service.titolo_servizi_struttura | lowercase"
            >
              <span v-html="service.descrizione_servizi_struttura"></span>
            </b-tab>
          </vb-tabs>

        </div>
      </section>
    </div>
  </div>
</template>

<script>


export default {
  name: 'information',

  props: {
    statics_detail: Object,
    product_detail: Array,
  },

  components: {
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style lang="scss">

  .information {
    @extend %section_margin;

    &__desc {
      text-align: center;
      position: relative;
      font-size: $font-size-lg;
      margin-bottom: 0;

      @include media-breakpoint-up(lg) {
        padding: 0 4.5em;
      }
    }
    &__icon {
      position: absolute;
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }

      &:first-child {
        top: 0;
        left: 0;
      }
      &:last-child {
        transform: rotate(180deg);
        bottom: 0;
        right: 0;
      }
    }

    .vb-tabs {
      margin-top: $grid-gutter-width*2;
    }
  }

</style>