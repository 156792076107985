<template>
  <div>
    <div v-for="(product, product_index) in product_detail" :key="_uid+'promo'+product_index">
      <section class="section promo" v-if="statics_detail[product.baseProdotto.pid].schedaProdotto.servizi_prodotto.length">
        <h2 class="vb-heading mb-4">Promo e proposte</h2>

        <vb-tabs
          :vb_options="{vertical: !is_mobile()}"
          :set_parsed_services="parsed_service = parse_servizi(product.baseProdotto.pid)"
        >
          <b-tab
              v-for="(service_product, service_product_index) in parsed_service"
              v-bind:key="_uid+service_product_index"
              :title="service_product.titolo_servizio !== '' && service_product.titolo_servizio | lowercase"
          >
            <p class="h5">{{service_product.titolo_servizio | uppercase}}</p>
            <span v-if="service_product.descrizione_servizio !== ''" v-html="service_product.descrizione_servizio.replaceAll('•', '<br>')" />
          </b-tab>
        </vb-tabs>

      </section>
    </div>
  </div>
</template>

<script>


export default {
  name: 'promo',

  props: {
    statics_detail: Object,
    product_detail: Array,
  },

  components: {
  },

  data() {
    return {
      black_terms : ['altre opportunità', 'promo proposte', 'programmi combinati', 'vantaggi']
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {

    parse_servizi(pid) {
      return this.statics_detail[pid].schedaProdotto.servizi_prodotto.filter(
        service => !this.black_terms.includes(service.titolo_servizio.toLowerCase())
      )
    }

  }
}
</script>

<style lang="scss">

  .promo {
    @extend %section_margin;
  }

</style>