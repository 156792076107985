<template>
  <div>
    <div v-for="(product, product_index) in product_detail" :key="_uid+'services-popular'+product_index">
      <section class="section services-popular"
               v-if="statics_detail[product.baseProdotto.pid].schedaProdotto.caratteristiche_prodotto.known.i_piu_amati"
      >
        <h2 class="vb-heading mb-4">I servizi più amati</h2>
        <b-row>
          <b-col lg="3"
                 v-for="(popular, popular_index) in statics_detail[product.baseProdotto.pid].schedaProdotto.caratteristiche_prodotto.known.i_piu_amati"
                 v-bind:key="_uid+popular_index">

            <vb-card :card="{
              title: popular.nome,
              text: popular.note_caratteristica,
            }" />

          </b-col>
        </b-row>
      </section>
    </div>
  </div>
</template>

<script>
import Card from '@library/blocks/card'

export default {
  name: 'services-popular',

  props: {
    statics_detail: Object,
    product_detail: Array,
  },

  components: {
    'vb-card': Card,
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>

  .services-popular {
    background-color: $white;
    border-radius: $border-radius-lg;
    padding: $grid-gutter-width;
  }

</style>