<template>
  <div class="vb-detail-view">
    <b-container>

      <booking-engine @search="() => { this.$router.push({ name: 'results' }) }"/>

      <section class="section section--loader" v-if="searching || loading">
        <loader size="large" :show_timing_slot="true" />
      </section>

      <section class="section" v-else>

        <div v-if="last_search !== current_search">
          <b-alert
            show
            variant="warning"
          >Attenzione, hai cambiato i criteri di ricerca ma non hai aggiornato i risultati. Clicca su Cerca.</b-alert>
          <br><br>
        </div>

        <detail-content-block
          :product_detail="product_detail"
          :statics_detail="statics_detail"
          :supplement="supplement"
          :product_brand="product_brand"
        />

      </section>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import {service_handler} from "@src/services";
import {custom_event, deep_clone} from "@src/utilities";

import DetailContentBlock from '@alpitour/components/views/content-blocks/detail.vue'


export default {
  name: 'detail-view',

  props: {

  },

  components: {
    'detail-content-block': DetailContentBlock
  },

  data() {
    return {
      loading: true,
      occupancy: this.$store.state.occupancy,
      flex_dates: this.$store.state.liveat.flex_dates,
      pid: this.$store.state.liveat.pid,
      departure_date: this.$store.state.liveat.departure_date,
      days: this.$store.state.liveat.days,
      departure_airports: this.$store.state.liveat.departure_airports,
      product_detail: [],
      statics_detail: {},
      supplement: [],
      product_brand: '',
    }
  },

  created() {
    this.deep_clone = deep_clone
  },

  computed: {
    // global state keys
    ...mapState([
      'searching',
    ]),    
    // module state keys
    ...mapState(
      'liveat', [
      'last_search',
      'current_search',
    ]),
  },

  mounted() {
    this.price()
  },

  methods: {

    getAllValuesForKey(obj, key) {
      let values = [];
      for (let prop in obj) {
        if (prop === key) {
          values.push(obj[prop]);
        } else if (typeof obj[prop] === 'object') {
          values = values.concat(this.getAllValuesForKey(obj[prop], key));
        }
      }
      return values;
    },

    price() {
      let parameters = {
        occupancy: this.occupancy[0],
        flex_dates: this.flex_dates,
        pid: this.pid,
        departure_date: this.departure_date,
        days: this.days
      }
      service_handler("price", parameters)
          .then(
              (success) => {
                this.log("[price.vue] - success")
                let tuttiGliAeroporti = this.getAllValuesForKey(this.departure_airports, 'iata_code');
                let selected_airports = this.departure_airports.filter(a => {return a.selected}).map(x=> {return x.iata_code})
                let model_products = []
                success.data.data.prodotti.forEach(product => {

                  custom_event('detail', product.baseProdotto.descrizProdotto + ' ' + product.baseProdotto.nomeHotel, product.baseProdotto.pid, 'product')

                  product.soluzioni_parsed = this.parse_soluzioni(product.soluzioni, tuttiGliAeroporti)


                  // filtra in base agli aeroporti selezionati
                  //product.soluzioni_parsed = product.soluzioni_parsed.filter(s => { return selected_airports.includes(s.aptPart)})

                  // ordina in base agli aeroporti selezionati
                  // (così da mettere sempre per primi gli aeroporti effettivamente ricercati)
                  product.soluzioni_parsed = product.soluzioni_parsed.sort((a, b) => {

                    const indexA = selected_airports.indexOf(a.aptPart);
                    const indexB = selected_airports.indexOf(b.aptPart);

                    if (indexA === -1 && indexB === -1) {
                      return 0;
                    } else if (indexA === -1) {
                      return 1;
                    } else if (indexB === -1) {
                      return -1;
                    } else {
                      return indexA - indexB;
                    }

                  });


                  delete product.soluzioni
                  model_products.push(product)
                })
                this.product_detail = model_products

                // preparo il model con i trattamenti, il primo selezionato
                this.supplement = this.product_detail.map((product) => {
                  return product.soluzioni_parsed.map((soluzione_parsed) => {
                    return soluzione_parsed.soluzioni.map((soluzione) => {
                      return soluzione.hotels.map((hotel) => {
                        return hotel.roomCombination.map((roomcomb) => {
                          return roomcomb.rooms.map((room) => {
                            let suppl =  room.trattamenti[0];
                            return  {
                              idGruppoTrattamento: suppl.idGruppoTrattamento,
                              totAmount: suppl.totAmount,
                              descrizioneOfferta: suppl.descrizioneOfferta
                            }
                          })
                        })
                      })
                    })
                  })
                })

                let tutteLeCamere = this.getAllValuesForKey(success.data.data.prodotti, 'tipoCam');
                const model_statics = {}
                Object.keys(success.data.data.statics).forEach(key_pid => {
                  success.data.data.statics[key_pid].schedaProdotto = this.parse_scheda(deep_clone(success.data.data.statics[key_pid].schedaProdotto), tutteLeCamere);
                  model_statics[key_pid] = success.data.data.statics[key_pid];
                })
                this.statics_detail = model_statics

                /*// questo fa si che ci sia la prima "soluzione" già selezionata
                this.room_selected(
                    {
                      product_index: 0,
                      solution_parsed_index: 0,
                      solution_index: 0,
                      hotel_index: 0,
                      combination_index: 0,
                      room_index: 0
                    }
                )
                // mette il bottone attivo alla prima soluzione già selezionata
                this.active_btn_room = '0-0-0'*/

                this.product_brand = (this.$store.state.liveat.pid).split('-')[1]
                this.loading = false

              },
              (error) => {
                this.log("[price.vue] - error")
                this.log(error.response)
                this.loading = false
                this.product_detail = []
                this.statics_detail = {}
              }
          )
    },

    parse_scheda(scheda, tutteLeCamere) {

      // CARATTERISTICHE PRODOTTO

      const carat_prod = {
        categoryMap : {},
        known_keys : ['i_piu_amati', 'panoramica_hotel', 'lista_servizi', 'panoramica_tour', 'perche_scegliere_questo_tour'],
        known : {}, // Referenziati a template
        unknown : {} // Stampati con un v-for
      }

      scheda.caratteristiche_prodotto.forEach(item => {

        if(item.categorie == '') {
          item.categorie.push('not_categorize')
        }

        item.categorie.forEach(category => {

          const categoryKey =
              category.toLowerCase().normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '').replace(/ /g, "_");

          if (!carat_prod.categoryMap[categoryKey]) carat_prod.categoryMap[categoryKey] = [];

          carat_prod.categoryMap[categoryKey].push(item);
        });
      })

      Object.keys(carat_prod.categoryMap).forEach(item => {
        const certified_item = carat_prod.known_keys.includes(item);
        const cat_obj = certified_item ? carat_prod.known : carat_prod.unknown;

        cat_obj[item] = carat_prod.categoryMap[item];
      })

      scheda.caratteristiche_prodotto = {
        'known' : carat_prod.known,
        'unknown': carat_prod.unknown
      }


      // INFORMAZIONI

      const info_prod = {
        infoMap : {},
        known_keys : tutteLeCamere,
        known : {}, // Referenziati a template
        unknown : {}, // Stampati con un v-for
        faq_struttura : []
      }

      scheda.informazioni.forEach(info => {
        info.cod === 'FAQG' ? info.testo_informazione.forEach(faq => {
          if(faq.titolo !== '') info_prod.faq_struttura.push(faq)
        }) : info_prod.infoMap[info.cod] = info
      })

      Object.keys(info_prod.infoMap).forEach(item => {
        const certified_item = info_prod.known_keys.includes(item);
        const cat_obj = certified_item ? info_prod.known : info_prod.unknown;

        cat_obj[item] = info_prod.infoMap[item];
      })

      scheda.informazioni = {
        'known' : info_prod.known,
        'unknown': info_prod.unknown
      }
      scheda.faq_struttura = info_prod.faq_struttura


      // PROGRAMMAZIONE TOUR

      if(this.departure_date) {

        scheda.programmi = scheda.programmi.filter(element => {

          if (element.data_validita_da === "" && element.data_validita_a === "") return true

          const start_date = element.data_validita_da;
          const end_date = element.data_validita_a;

          return this.departure_date.localeCompare(start_date) > 0 && this.departure_date.localeCompare(end_date) < 0;
        });
      }


      return scheda
    },

    parse_soluzioni(product, tuttiGliAeroporti) {

      const solutionMap = {},
            known_keys = tuttiGliAeroporti

      product.forEach(solution => {
        solution.flights.forEach(flight => {

          if(known_keys.includes(flight.aptPart)){

            const solutionKey = flight.descrAptPart

            if (!solutionMap[solutionKey]){
              solutionMap[solutionKey] = {
                label: solutionKey,
                aptPart: flight.aptPart,
                soluzioni: []
              };
            }
            solutionMap[solutionKey].soluzioni.push(solution)
          }
        })

      })

      product = Object.values(solutionMap);

      return product
    },
  }

}
</script>
<style lang="scss" scoped>
</style>