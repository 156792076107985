<template>
  <div>
    <div v-for="(product, product_index) in product_detail" :key="_uid+'program'+product_index">
      <section class="section program"
               v-if="statics_detail[product.baseProdotto.pid].schedaProdotto.programmi.length"
      >
        <h2 class="program__title vb-heading mb-4">Itinerario</h2>
        <p class="program__desc vb-heading mb-4">Maggiori informazioni in merito all'orario e al punto di ritrovo del primo/ultimo giorno verranno comunicate a seguito della prenotazione.</p>
        <div class="program__wrap">
          <div class="program__element"
               v-for="(plan, plan_index) in statics_detail[product.baseProdotto.pid].schedaProdotto.programmi"
               v-bind:key="_uid+plan_index"
          >
            <div class="program__item" v-if="plan.titolo_programma !== 'IL PROGRAMMA'">
              <div class="program__item-body">
                <h4 class="program__item-title" v-b-toggle="'plan'+plan_index"><span class="vb-heading">{{plan.titolo_programma}}</span></h4>
                <b-collapse class="program__item-collapse" :id="'plan'+plan_index" accordion="accordion_plan">
                  <p class="program__item-text">
                    <img v-if="plan.icona_programma.src != ''" :src="plan.icona_programma.src" class="program__item-image" />
                    <span>{{plan.descrizione_programma}}</span>
                  </p>
                </b-collapse>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'program',

  props: {
    statics_detail: Object,
    product_detail: Array,
  },

  components: {
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style lang="scss">

  .program {
    $this: &;
    @extend %section_margin;

    &__wrap > div {
      margin-bottom: $grid-gutter-width/4;

      @include media-breakpoint-up(lg) {
        margin-bottom: 1.25rem;
      }
    }

    &__element {

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__item {
      border: none;
      border-radius: $border-radius-lg*4;
      background-color: $white;
      display: flex;

      &-body {
        padding: 0;
        position: relative;

        @include media-breakpoint-up(lg) {
          &:after {
            $size: .55rem;
            position: absolute;
            top: 3.625rem;
            left: 1.33rem;
            content: '';
            width: $size;
            height: $size;
            border-radius: 100%;
            background-color: $gray-400;
            z-index: -1;

            @at-root .vb-plan__item:last-child & {
              display: none;
            }

            @at-root #{$this}__item:last-child & {
              display: none;
            }
          }
        }
      }

      &-title {
        margin-bottom: 0;
        font-size: $font-size-lg;
        display: flex;
        align-items: center;
        position: relative;
        padding: $grid-gutter-width/2;
        transition: color 0.2s ease-out;

        &:before {
          display: inline-block;
          border-radius: 100%;
          content: '';
          $size: 1.25rem;
          width: $size;
          height: $size;
          background-color: $secondary;
          margin-right: $grid-gutter-width;
          transition: transform 0.2s linear, background-color 0.3s ease-out;
        }

        &:hover, &.not-collapsed {
          color: $secondary;

          &:before {
            transform: scale(1.15);
            background-color: $info;
          }
        }

        .vb-heading {
          &:after {
            color: $black;
          }
        }
      }

      &-image {
        margin-bottom: $grid-gutter-width/2;

        @include media-breakpoint-up(lg) {
          width: 15rem;
          margin-right: 1.25rem;
          margin-bottom: 0;
        }
      }

      &-text {
        margin-top: .625rem;
        margin-bottom: $grid-gutter-width/2 !important;
        padding-left: 4.063rem;
        padding-right: 4.063rem;
        position: relative;

        @include media-breakpoint-up(lg) {
          display: flex;
        }

        &:after {
          position: absolute;
          top: 0;
          left: 1.33rem;
          content: '';
          width: .55rem;
          height: 100%;
          border-radius: 1.25rem;
          background-color: $gray-400;
        }
      }
    }
  }

</style>